<template>
  <v-dialog
      v-model="visibility"
      max-width="800"
      content-class="cross__dialog"
      :persistent="changed"
      :transition="$dialogTransition"
      @click:outside="showConfirmDialog"
      scrollable
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="primary"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        {{
          type == "edit" ? $t("home.update_stream") : $t("home.create_stream")
        }}
      </v-card-title>
      <v-card-text class="pb-5 pt-4">
        <div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputStream.name"
                  :label="$t('form.title')"
                  :maxlength="25"
                  hide-details="auto"
                  :error-messages="nameErrors"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
<!--            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputStream.price"
                  :label="$t('offersCatalog.price')"
                  :maxlength="25"
                  hide-details="auto"
                  :error-messages="priceErrors"
                  @keypress="validationForPrice($event)"
                  @paste="validationForPrice($event)"
              />
            </v-col>-->
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputStream.discount"
                  :label="$t('offersCatalog.discount')"
                  :maxlength="25"
                  hide-details="auto"
                  :error-messages="discountErrors"
                  @keypress="validationForDiscount($event)"
                  @paste="validationForDiscount($event)"
              />
            </v-col>
<!--            <v-col cols="12">
              <v-text-field
                :label="$t('form.phone_number')"
                dense
                outlined
                color="primary"
                hide-details="auto"
                prefix="+38"
                v-on:keypress="validationForPhoneNumber($event)"
                @paste="validationForPhoneNumber($event)"
                v-model="inputStream.phone_number"
                :maxLength="10"
                :error-messages="phoneErrors"
                class="auth-input"
              />
            </v-col>-->
            <v-col v-if="type !== 'create'" cols="12">
              <v-autocomplete
                  :items="offersList"
                  v-model="inputStream.offer_id"
                  :error-messages="offerErrors"
                  :item-value="'id'"
                  :item-text="'name'"
                  :label="$t('form.offers')"
                  dense
                  outlined
                  persistent-hint
                  @change="chooseOffer()"
                  :disabled="type == 'edit'"
              >
              </v-autocomplete>
            </v-col>

          </v-row>
          <div v-for="(category, key) in categories" :key="key" class="mb-6">
            <v-row v-if="offer?.[key]?.length">
              <v-col class="ml-3">{{ $t(category.title) }}</v-col>
            </v-row>
            <v-row v-if="offer?.[key]?.length">
              <v-col cols="12" class="pt-2 pb-0" style="max-height: 300px; overflow-y: auto;">
                <v-row v-for="(item, index) in offer[key]" :key="index" class="align-center px-4 py-2" style="border: 1px solid #eee;">
                  <v-col cols="1">
                    <v-checkbox
                        @click.prevent="toggleSelection(item, key)"
                        :value="item[category.selectedKey]"
                        hide-details
                        color="primary"
                        class="mr-2"
                        :disabled="key === 'prices' && item.is_selected_price && selectedPricesCount === 1"
                    ></v-checkbox>
                  </v-col>
                  <template v-if="key !== 'prices'">
                  <v-col cols="auto" class="font-weight-bold" :style="$vuetify.theme.dark ? 'color: #FFF' : 'color: #000'">
                    {{ reducedName(item.name) }}
                  </v-col>
                  <v-col v-if="item.url" cols="auto">
                    <v-btn :href="item.url" target="_blank" text class="pa-0" style="text-decoration: underline; color: inherit;">
                      {{ reducedName(item.url) }}
                    </v-btn>
                  </v-col>
                  </template>
                  <template v-else-if="key === 'prices'">
                    <v-col cols="auto" class="d-flex" :style="$vuetify.theme.dark ? 'color: #FFF' : 'color: #000'">
                      <div class="font-weight-bold pr-3">{{ $t('settings.price') }} :</div> {{ item.price }}
                    </v-col>
                    <v-col cols="auto" class="d-flex" :style="$vuetify.theme.dark ? 'color: #FFF' : 'color: #000'">
                      <div class="font-weight-bold pr-3">{{ $t('form.payment') }} :</div> {{ item.payout }}
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col class="ml-3">
              {{ $t("form.UTMTags") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputStream.utm_source"
                  label="source"
                  :maxlength="15"
                  hide-details="auto"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputStream.utm_medium"
                  label="medium"
                  :maxlength="15"
                  hide-details="auto"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputStream.utm_campaign"
                  label="campaign"
                  :maxlength="15"
                  hide-details="auto"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2" class="auth-input">
              <v-text-field
                  label="content"
                  dense
                  outlined
                  color="primary"
                  hide-details="auto"
                  v-on:keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
                  v-model="inputStream.utm_content"
                  :maxLength="15"
                  class="auth-input"
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
              <v-text-field
                  label="term"
                  dense
                  outlined
                  color="primary"
                  hide-details="auto"
                  v-on:keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
                  v-model="inputStream.utm_term"
                  :maxLength="15"
                  class="auth-input"
              />
            </v-col>

          </v-row>
          <v-row>
            <v-col class="ml-3">
              {{ $t("form.AddLabels") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputStream.additional_1"
                  label="sub1"
                  :maxlength="15"
                  hide-details="auto"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputStream.additional_2"
                  label="sub2"
                  :maxlength="15"
                  hide-details="auto"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputStream.additional_3"
                  label="sub3"
                  :maxlength="15"
                  hide-details="auto"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputStream.additional_4"
                  label="sub4"
                  :maxlength="15"
                  hide-details="auto"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
              <v-text-field
                  label="sub5"
                  dense
                  outlined
                  color="primary"
                  hide-details="auto"
                  v-on:keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
                  v-model="inputStream.additional_5"
                  :maxLength="15"
                  class="auth-input"
              />
            </v-col>

          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn
                dark
                block
                class="br-5 text-transform-none"
                @click="showConfirmDialog"
            >{{ $t("btn.cancel") }}</v-btn
            >
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
                v-if="type === 'edit'"
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onUpdate()"
            >{{ $t("btn.update") }}</v-btn
            >
            <v-btn
                v-if="type === 'create' || type === 'createStream'"
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onCreate()"
            >{{ $t("btn.create") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
        :visible="visibleConfirm"
        @close="visibleConfirm = false"
        @delete="onClose"
    />
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  validationForPhoneNumber,
  validationForTitle,
  validationForPrice,
  validationForDiscount
} from "@/mixins/helpers";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import tImgMixin from "@/mixins/tImgMixin";
import offersService from "@/services/request/offers/offersService";
import _ from "lodash";
import offersCatalogMixin from "@/components/offersCatalog/offersCatalogMixin";
import streamsService from "@/services/request/streams/streamsService";
import phoneMask from "@/validators/phoneMask";

export default {
  data: () => ({
    loading: false,
    changed: false,
    visibleConfirm: false,
    inputStream: {},
    copyStream: {},
    stream: {},
    landings: [],
    offer: {},
    offersList: [],
    selectedPricesCount: 0,
    categories: {
      advertorials: { title: 'form.advertorials', selectedKey: 'is_selected_advertorial' },
      product_pages: { title: 'offersCatalog.productPages_without_form', selectedKey: 'is_selected_product_page' },
      landings: { title: 'offersCatalog.productPages_with_form', selectedKey: 'is_selected_landing' },
      prices: { title: 'form.prices', selectedKey: 'is_selected_price' }
    }
  }),
  mixins: [dialogMixin, validationMixin, notifications, tImgMixin, offersCatalogMixin],
  validations: {
    inputStream: {
      name: { required },
   /*   price: { required,  isGreaterThanOfferPrice() {
          return Number(this.inputStream.price) >= Number(this.offer.price);
        } },*/
      discount: { required },
      /*phone_number: { required, phoneMask },*/
    },
    offer: {
      id: { required },
    },
  },
  components: {
    ConfirmLeftDialog,
  },
  props: {
    type: {
      required: true,
    },
    inputOffer: {
      type: Object,
      required: false,
    },
    selectedStream: {
      type: Object,
      required: false,
    }
  },
  async mounted() {
    this.loading = true;
    await this.onMounted();
    this.loading = false;
  },
  methods: {
    validationForDiscount,
    validationForTitle,
    reducedName(item) {
      if (item.length > 40) {
        return item.substring(0, 40) + "...";
      }
      return item;
    },
    async onMounted() {
      await this.getOffersList();

      if (this.type === 'createStream') {
        this.initializeStream();
      } else {
        if (this.selectedStream && Object.keys(this.selectedStream).length) {
          this.stream = this.selectedStream;
          await this.getOffer();
          await this.setSelected();
        } else {
          this.initializeStream(true);
          this.offer = { ...this.inputOffer };
        }
      }

      this.cloneStream();
    },

    initializeStream(includeExtraFields = false) {
      const baseStream = {
        name: "",
        discount: "",
        offer_id: "",
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_content: "",
        utm_term: "",
        additional_1: "",
        additional_2: "",
        additional_3: "",
        additional_4: "",
        additional_5: "",
      };

      if (includeExtraFields) {
        Object.assign(baseStream, {
          landings: [],
          product_pages: [],
          advertorials: [],
          prices: [],
        });
      }

      this.stream = baseStream;
    },

    cloneStream() {
      this.inputStream = _.cloneDeep(this.stream);
      this.copyStream = _.cloneDeep(this.stream);
    },
    setSelected() {
      const updateSelection = (items, selectedItems, key) => {
        items?.forEach(item => {
          if (selectedItems?.find(selected => selected.id === item.id)) {
            item[key] = true;
            if (key === 'is_selected_price') this.selectedPricesCount++;
          }
        });
      };

      updateSelection(this.offer?.prices, this.selectedStream?.prices, 'is_selected_price');
      updateSelection(this.offer?.landings, this.selectedStream?.landings, 'is_selected_landing');
      updateSelection(this.offer?.product_pages, this.selectedStream?.product_pages, 'is_selected_product_page');
      updateSelection(this.offer?.advertorials, this.selectedStream?.advertorials, 'is_selected_advertorial');
    },
    chooseOffer() {
      this.offer = this.offersList.find(offer => offer.id === this.inputStream.offer_id) || {};
    },
    async getOffer() {
      try {
        this.loading = true;
        this.offer = await offersService.getOffer(this.selectedStream.offer_id);
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async getOffersList() {
      try {
        this.loading = true;
        const response = await offersService.getOffers();
        this.offersList = response.data;
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    toggleSelection(item, key) {
      const selectedKey = this.categories[key].selectedKey;
      item[selectedKey] = !item[selectedKey];

      if (item[selectedKey]) {
        this.inputStream[key]?.push(item);
      } else {
        const index = this.inputStream[key]?.findIndex(i => i.id === item.id);
        if (index !== -1) {
          this.inputStream[key].splice(index, 1);
        }
      }

      if (key === 'prices') {
        this.selectedPricesCount = this.offer?.prices?.filter(i => i.is_selected_price)?.length || 0;
      }
    },
    onClose() {
      this.$v.$reset();
      this.$emit("close");
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onClose();
    },
    async onCreate() {
      if (!this.validateForm()) return;
      this.loading = true;
      try {
        const formData = this.prepareFormData();
        const newStream = await streamsService.addStream(formData);
        if (this.type === "createStream") {
          this.$emit("createStream", newStream);
        }
        this.setSuccessNotification(this.$t("home.successfully_created_stream"));
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.finalizeRequest();
      }
    },

    async onUpdate() {
      if (!this.validateForm()) return;
      this.loading = true;
      try {
        const formData = this.prepareFormData(true);
        const changedItem = await streamsService.updateStream(this.inputStream.id, formData);
        this.$emit("streamModified", changedItem);
        this.setSuccessNotification(this.$t("home.successfully_updated_stream"));
        this.visibility = false;
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.finalizeRequest();
      }
    },

    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      if (parseFloat(this.inputStream.discount) > 99) {
        this.setErrorNotification(this.$t("form.errors.DiscountCannotExceed99"));
        return false;
      }
      return true;
    },

    prepareFormData(isUpdate = false) {
      const formData = new FormData();
      if (isUpdate) formData.append("_method", "patch");
      const changedData = this.$getChangedData(this.inputStream, this.copyStream);

      changedData.forEach(({ field, value }) => {
        if (!["landings", "prices", "advertorials", "product_pages"].includes(field)) {
          formData.append(field, value);
        }
      });

      this.appendSelectedItems(formData, "landings", this.offer.landings);
      this.appendSelectedItems(formData, "product_pages", this.offer.product_pages);
      this.appendSelectedItems(formData, "advertorials", this.offer.advertorials);
      this.appendSelectedItems(formData, "prices", this.offer.prices);

      if (isUpdate) {
        if (!this.inputStream.landings.length) formData.append("landings", "");
        if (!this.inputStream.product_pages.length) formData.append("product_pages", "");
        if (!this.inputStream.advertorials.length) formData.append("advertorials", "");
        if (!this.inputStream.prices.length) formData.append("prices", "");
      }

      formData.append("offer_id", this.offer.id);
      return formData;
    },

    appendSelectedItems(formData, key, items) {
      items.forEach((item, index) => {
        if (item[`is_selected_${key.slice(0, -1)}`]) {
          formData.append(`${key}[${index}]`, item.id);
        }
      });
    },

    finalizeRequest() {
      this.$v.$reset();
      this.$emit("close");
      this.loading = false;
    }
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.inputStream.name.$dirty) {
        return errors;
      }
      !this.$v.inputStream.name.required &&
      errors.push(this.$t("form.errors.TitleRequired"));
      return errors;
    },/*
    priceErrors() {
      const errors = [];
      if (!this.$v.inputStream.price.$dirty) {
        return errors;
      }

      !this.$v.inputStream.price.required &&
      errors.push(this.$t("form.errors.PriceRequired"));

      if(Number(this.offer.price)) {
        !this.$v.inputStream.price.isGreaterThanOfferPrice && errors.push(this.$t("form.errors.PriceMoreThanRequired"));
      }

      return errors;
    },*/
    discountErrors() {
    const errors = [];
    if (!this.$v.inputStream.discount.$dirty) {
      return errors;
    }
    !this.$v.inputStream.discount.required &&
    errors.push(this.$t("form.errors.DiscountRequired"));
    if (this.inputStream.discount > 99) {
      errors.push(this.$t("form.errors.DiscountCannotExceed99"));
    }
    return errors;
  },
    offerErrors() {
      const errors = [];
      if (!this.$v.offer.id.$dirty) {
        return errors;
      }
      !this.$v.offer.id.required &&
      errors.push(this.$t("form.errors.OfferRequired"));
      return errors;
    },
  },
  watch: {
    inputStream: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyStream, this.inputStream);
      },
    },
  },
};
</script>

<style>
.auth-input .v-input__control {
  display: flex;
  align-items: flex-start;
}

</style>
